import {
	CircleMarkerProps,
	createPathComponent,
	updateCircle,
	LeafletContextInterface,
	LeafletElement,
} from "@react-leaflet/core";
import L, { CircleMarker, Util } from "leaflet";

import * as PIXI from "pixi.js";
import { WebCanvas } from "./WebCanvas";

L.Map.include({
	getWebGlRenderer: function (layer: any) {
		let renderer = layer.options.renderer || this._rendererWebGL;

		if (!renderer) {
			renderer = this._rendererWebGL = this._createWebGlRenderer();
		}

		if (!this.hasLayer(renderer)) {
			this.addLayer(renderer);
		}
		return renderer;
	},

	_createWebGlRenderer: function (options: any) {
		let renderer = new WebCanvas();
		return renderer;
	},
});

export const LeafletCanvasMarker: any = L.CircleMarker.extend({
	initialize: function (latlng: any, options: any) {
		Util.setOptions(this, options);
		this._latlng = L.latLng(latlng);
		this._radius = this.options.radius;
	},

	beforeAdd: function (map: any) {
		this._renderer = map.getWebGlRenderer(this);
	},
	_updatePath: function () {
		let app: PIXI.Application = this._renderer._app;
		let sprite: PIXI.Sprite = this.sprite;
		let point: L.Point = this._point;
		if (!app.stage.children.includes(sprite)) return;
		sprite.x = point.x;
		sprite.y = point.y;
	},
	getOpacity: function () {
		return this._opacity;
	},
	setOpacity: function (opacity: any) {
		this._opacity = opacity;
		this.sprite.alpha = opacity;
	},
	handleClick: function (layer: any) {
		const { eventHandlers } = layer.options;
		if (eventHandlers?.click) {
			eventHandlers.click();
		}
	},
});

export interface SVGMarkerProps {
	iconUrl: string;
	size: [number, number];
}

export function extendContext(
	source: LeafletContextInterface,
	extra: Partial<LeafletContextInterface>
): LeafletContextInterface {
	return { ...source, ...extra };
}

export function createElementObject<T, C = any>(
	instance: T,
	context: LeafletContextInterface,
	container?: C | null
): LeafletElement<T, C> {
	return { instance, context, container };
}

function createCircleMarker(props: SVGMarkerProps & CircleMarkerProps, ctx: LeafletContextInterface) {
	const { center, ...options } = props;
	const instance: CircleMarker<any> = new LeafletCanvasMarker(center, options);
	let ret: LeafletElement<CircleMarker<any>, any> = {
		instance,
		context: { ...ctx, overlayContainer: instance },
	};
	return ret;
}

const CanvasMarker = createPathComponent<CircleMarker, SVGMarkerProps & CircleMarkerProps>(
	createCircleMarker,
	updateCircle
);

export default CanvasMarker;
