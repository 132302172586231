import "./App.scss";
import { MainContainer } from "./components";
import { MainSidebar, MapView, TopMapNav } from "./features";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PinLinkModeState, RightSidebarOpenState, SidebarOpenState } from "./store";
import { useRecoilValue } from "recoil";
import { cx } from "@emotion/css";
import useScreen from "use-screen";
import { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSiteOptions } from "./hooks";
import "./smoothzoom";
import React from "react";
const queryClient = new QueryClient();

const App = () => {
	const isSidebarOpen = useRecoilValue(SidebarOpenState);
	const isRightSidebarOpen = useRecoilValue(RightSidebarOpenState);
	const isPinLinkMode = useRecoilValue(PinLinkModeState);

	const isMarkerMode = isPinLinkMode;
	const { isMobile } = useScreen();
	const { siteOptions } = useSiteOptions();

	return (
		<Suspense fallback={null}>
			<QueryClientProvider client={queryClient}>
				{siteOptions.state === "hasValue" && siteOptions.contents && (
					<GoogleOAuthProvider clientId={siteOptions.contents.clientId}>
						<MainContainer
							className={cx({
								"is-mobile": isMobile,
								"left-sidebar-open": isSidebarOpen,
								"right-sidebar-open": isRightSidebarOpen,
								"pinlink-mode": isPinLinkMode,
							})}
						>
							<MainSidebar />
							<TopMapNav />
							<MapView />
							<ToastContainer />
						</MainContainer>
					</GoogleOAuthProvider>
				)}
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</Suspense>
	);
};

export default App;
