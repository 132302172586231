import { Slide, ToastOptions } from "react-toastify";

export const base_url = "admin";
// export const dev_base_url = "https://interactivemap.app/test1/admin";
export const dev_base_url = "http://localhost:1111/map/admin";
export const sidebarSizes = {
	left: 400,
	right: 300,
};

export const toastOptions: ToastOptions = {
	position: "bottom-center",
	theme: "light",
	autoClose: 1000,
	hideProgressBar: true,
	transition: Slide,
};
